import React from "react";
import classnames from "classnames";
import { Option } from "components/DemographicInformation/dropdown-options";
import InputWrapper, { FormWrapperProps } from "../InputWrapper/InputWrapper";
import styles from "./DropDownSelect.module.scss";
import classNames from "classnames";

type DisplayOptions = {
  size?: "normal" | "slim";
  inverse?: boolean;
  inline?: boolean;
};

type RefReturn =
  | string
  | ((instance: HTMLSelectElement | null) => void)
  | React.RefObject<HTMLSelectElement>
  | null
  | undefined;

type Props = {
  options: Option[];
  displayOptions?: DisplayOptions;
  register: RefReturn;
  wrapperClass?: string;
  defaultOptionValue?: string;
  datacy?: string;
} & FormWrapperProps &
  React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  >;

const DropDownSelect = React.forwardRef<HTMLSelectElement, Props>(
  (props, ref) => {
    const {
      options,
      displayOptions,
      register,
      wrapperClass,
      className,
      ...allOtherProps
    } = props;
    return (
      <InputWrapper
        {...allOtherProps}
        className={classNames(
          wrapperClass,
          props.displayOptions?.inline && styles.inline
        )}
      >
        <select
          {...allOtherProps}
          name={props.name}
          title={props.label}
          defaultValue={""}
          className={classnames(
            styles.DropDownSelect,
            {
              [styles.DropDownSelect_Slim]:
                props.displayOptions?.size === "slim",
              [styles.DropDownSelect_Inverse]: props.displayOptions?.inverse,
              [styles.DropDownSelect_Error]: props.error,
            },
            className
          )}
          ref={register}
          data-cy={props.datacy}
        >
          {props.defaultOptionValue && (
            <option value={""} disabled>
              {props.defaultOptionValue}
            </option>
          )}
          {options.map((option) => (
            <option
              key={`dropdown-${props.name}-${option.value}`}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </select>
      </InputWrapper>
    );
  }
);

export default DropDownSelect;
