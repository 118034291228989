import * as React from "react";
import { parseIncludesForItem } from "@tiicker/util/lib/contentful/helpers";
import { Entry, Asset } from "contentful";

type PropsWithEntry<TEntry> = {
  entry: Entry<TEntry>;
};

type ContentfulLinkedEntryProps<
  T extends PropsWithEntry<TEntry>,
  TEntry
> = Omit<T, "entry"> & {
  includesList: { Entry: Entry<TEntry>[] };
  entryId: string;
  component: React.FunctionComponent<T>;
};

export const ContentfulLinkedEntry = <T extends PropsWithEntry<TEntry>, TEntry>(
  props: ContentfulLinkedEntryProps<T, TEntry>
) => {
  if (!props.entryId) {
    return <></>;
  }

  const entry = parseIncludesForItem(props.includesList, props.entryId);

  if (!entry) {
    throw new Error(`List did not include entry ${props.entryId}`);
  }

  const args: T = {
    entry: entry!,
    ...props,
  } as any as T;

  return (
    <>
      <props.component {...args} />
    </>
  );
};

type PropsWithAsset = {
  asset: Asset;
};

type ContentfulLinkedAssetProps<T extends PropsWithAsset> = Omit<T, "asset"> & {
  includesList: { Asset: Asset[] };
  assetId: string;
  component: React.FunctionComponent<T>;
  className?: string;
};

export const ContentfulLinkedAsset = <T extends PropsWithAsset>(
  props: ContentfulLinkedAssetProps<T>
) => {
  if (!props.assetId) {
    return <></>;
  }

  const asset = parseIncludesForItem(props.includesList, props.assetId);

  if (!asset) {
    console.error(`List did not include asset ${props.assetId}`);
    return <></>;
  }

  const args: T = {
    asset: asset!,
    ...props,
  } as any as T;

  return (
    <>
      <props.component {...args} />
    </>
  );
};
