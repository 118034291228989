import { ClaimedPerkOrTierOptionArgs } from "$gql/types.gen";
import { getContentfulClient } from "@tiicker/util/lib/contentful/client";
import { getItemReference } from "@tiicker/util/lib/contentful/helpers";
import {
  ContentfulContentType,
  GenericReference,
  PerkDocument,
  PerkOptionDocument,
  TangoGiftCardDocument,
} from "@tiicker/util/lib/contentful/types";
import CheckboxGroup from "components/core/Forms/CheckboxGroup/CheckboxGroup";
import DropDownSelect from "components/core/Forms/DropDownSelect/DropDownSelect";
import RadioGroup from "components/core/Forms/RadioGroup/RadioGroup";
import TextInput from "components/core/Forms/TextInput/TextInput";
import { Entry } from "contentful";
import { compact } from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import styles from "./PerkOptions.module.scss";

type Props = {
  perk: Entry<PerkDocument>;
  includes: any;
  selectedOptions: ClaimedPerkOrTierOptionArgs[];
  setSelectedOptions: (options: ClaimedPerkOrTierOptionArgs[]) => void;
  perkId: string;
  setPerkOptions: (options: PerkOption[]) => void;
  loadedPerkOptions: PerkOption[];
};

type SingleOption = {
  option: PerkOptionDocument;
  selectedOptions: ClaimedPerkOrTierOptionArgs[];
  setSelectedOptions: (options: ClaimedPerkOrTierOptionArgs[]) => void;
  perkId: string;
};

export type PerkOption = {
  title: string;
  description: string;
  options: string[];
  displayType: "Radios" | "Checkboxes" | "Dropdown" | "Textbox";
  conditionalDisplay: string[];
  perkId: string;
};

const loadContentfulOptions = async (
  options: GenericReference<PerkOptionDocument>[],
  perkId: string,
  assetList: any
): Promise<PerkOption[]> => {
  const hasNotLoadedOptions = options.some(
    (op) => getItemReference(op, assetList) === null
  );

  const loadedOptions = hasNotLoadedOptions
    ? await getContentfulClient("contentful").getEntries<PerkOptionDocument>({
        content_type: ContentfulContentType.PerkOption,
        "sys.id[in]": options.map((x) => x.sys.id).join(","),
      })
    : undefined;

  const finalOptions = loadedOptions
    ? loadedOptions.items
    : compact(options.map((op) => getItemReference(op, assetList)));
  return finalOptions.map((op) => ({
    title: op?.fields.title ?? "",
    description: op?.fields.description ?? "",
    options: op?.fields.options ?? [],
    displayType: op?.fields.displayType ?? "Dropdown",
    conditionalDisplay: op?.fields.conditionalDisplay ?? undefined,
    perkId: perkId,
  }));
};

const loadContentfulTango = async (
  tangoCards: Entry<TangoGiftCardDocument>[],
  perkId: string
): Promise<PerkOption[]> => {
  const hasNotLoadedCards = tangoCards.some(
    (tangoCard) => tangoCard.fields === undefined
  );
  const loadedCards = hasNotLoadedCards
    ? await getContentfulClient("contentful").getEntries<TangoGiftCardDocument>(
        {
          content_type: ContentfulContentType.TangoGiftCard,
          "sys.id[in]": tangoCards.map((x) => x.sys.id).join(","),
        }
      )
    : undefined;

  const finalOptions = loadedCards ? loadedCards.items : tangoCards;
  return [
    {
      title: "Gift Card Options",
      description: "",
      options: finalOptions.map(
        (op) => op.fields.giftCardName || "Unknown Gift card"
      ),
      displayType: "Dropdown",
      conditionalDisplay: [],
      perkId: perkId,
    },
  ];
};

export const getOptionsFromPerk = (
  perk: Entry<PerkDocument>,
  assetList: any
): PerkOption[] => {
  const [options, setOptions] = useState<PerkOption[]>([]);
  const loadEntries = async (): Promise<PerkOption[]> => {
    if (perk.fields.options && perk.fields.options.length > 0) {
      return loadContentfulOptions(perk.fields.options, perk.sys.id, assetList);
    }

    if (perk.fields.tangoGiftCards) {
      return loadContentfulTango(perk.fields.tangoGiftCards, perk.sys.id);
    }

    return [];
  };

  const run = async () => {
    const newOptions = await loadEntries();
    setOptions(newOptions);
  };

  useEffect(() => {
    run();
  }, [perk]);

  return options;
};

const DropdownOption = (props: SingleOption) => {
  return (
    <div key={`option-${props.option.title}`} className={styles.optionWrapper}>
      <DropDownSelect
        datacy="PerkOptionDropdown"
        options={props.option.options.map((o) => {
          return {
            label: o,
            value: o,
          };
        })}
        register={null}
        // label={`Select your ${props.option.title}`}
        name={props.option.title}
        displayOptions={{
          size: "slim",
        }}
        className={styles.dropdown}
        onChange={(option) => {
          props.setSelectedOptions([
            {
              option: props.option.title,
              value: option.target.value,
              perkId: props.perkId,
            },
            ...props.selectedOptions.filter(
              (x) => x.option !== props.option.title
            ),
          ]);
        }}
        defaultOptionValue={`Select your ${props.option.title}`}
      />
    </div>
  );
};

const CheckboxOption = (props: SingleOption) => {
  return (
    <div key={`option-${props.option.title}`}>
      <CheckboxGroup
        options={props.option.options.map((o) => {
          return {
            label: o,
            value: o,
          };
        })}
        register={null}
        label={props.option.title}
        name={props.option.title}
        onChange={(option) => {
          if (option.target.checked) {
            props.setSelectedOptions([
              {
                option: props.option.title,
                value: option.target.value,
                perkId: props.perkId,
              },
              ...props.selectedOptions,
            ]);
          } else {
            props.setSelectedOptions([
              ...props.selectedOptions.filter(
                (x) => x.value !== option.target.value
              ),
            ]);
          }
        }}
      />
    </div>
  );
};

const RadioOption = (props: SingleOption) => {
  return (
    <div key={`option-${props.option.title}`}>
      <RadioGroup
        options={props.option.options.map((o) => {
          return {
            label: o,
            value: o,
          };
        })}
        register={null}
        label={props.option.title}
        name={props.option.title}
        onChange={(option) => {
          props.setSelectedOptions([
            {
              option: props.option.title,
              value: option.target.value,
              perkId: props.perkId,
            },
            ...props.selectedOptions.filter(
              (x) => x.option !== props.option.title
            ),
          ]);
        }}
      />
    </div>
  );
};

const TextOption = (props: SingleOption) => {
  return (
    <div key={`option-${props.option.title}`}>
      <TextInput
        register={null}
        wrapperClass={styles.AccountInformationModalContent__formGroup}
        className={styles.input}
        // label={props.option.description}
        placeholder={props.option.title}
        name={props.option.title}
        onChange={(option) => {
          if (!option.target.value) {
            props.setSelectedOptions([
              ...props.selectedOptions.filter(
                (x) => x.option !== props.option.title
              ),
            ]);
          } else {
            props.setSelectedOptions([
              {
                option: props.option.title,
                value: option.target.value,
                perkId: props.perkId,
              },
              ...props.selectedOptions.filter(
                (x) => x.option !== props.option.title
              ),
            ]);
          }
        }}
        data-cy="PerkOptionTextOption"
      />
    </div>
  );
};

const PerkOptions = (props: Props) => {
  const options = getOptionsFromPerk(props.perk, props.includes);

  useEffect(() => {
    props.setPerkOptions([...options, ...props.loadedPerkOptions]);
  }, [options]);

  return (
    <div className={styles.container}>
      {options.map((option) => {
        if (
          option.conditionalDisplay &&
          option.conditionalDisplay.length > 0 &&
          (!props.selectedOptions ||
            !props.selectedOptions.some((x) =>
              option.conditionalDisplay?.some(
                (y) => x.value.toLowerCase() === y.toLowerCase()
              )
            ))
        ) {
          return <></>;
        }

        const singleProps: SingleOption = {
          option,
          selectedOptions: props.selectedOptions,
          setSelectedOptions: props.setSelectedOptions,
          perkId: props.perkId,
        };
        switch (option.displayType) {
          case "Radios":
            return <RadioOption {...singleProps} />;
            break;
          case "Checkboxes":
            return <CheckboxOption {...singleProps} />;
            break;
          case "Dropdown":
            return <DropdownOption {...singleProps} />;
            break;
          case "Textbox":
            return <TextOption {...singleProps} />;
            break;
          default:
            return <></>;
        }
      })}
    </div>
  );
};

export default PerkOptions;
