import classnames from "classnames";
import { Option } from "components/DemographicInformation/dropdown-options";
import React from "react";
import InputWrapper, { FormWrapperProps } from "../InputWrapper/InputWrapper";
import styles from "./CheckboxGroup.module.scss";

type DisplayOptions = {
  orientation?: "horizontal" | "vertical";
  inverse?: boolean;
};

type RefReturn =
  | string
  | ((instance: HTMLInputElement | null) => void)
  | React.RefObject<HTMLInputElement>
  | null
  | undefined;

type Props = {
  options: Option[];
  displayOptions?: DisplayOptions;
  register: RefReturn;
  wrapperClass?: string;
} & FormWrapperProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;

const CheckboxGroup = React.forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const {
      className,
      options,
      displayOptions,
      register,
      wrapperClass,
      ...allOtherProps
    } = props;
    return (
      <InputWrapper {...allOtherProps} className={classnames(wrapperClass)}>
        <div
          className={classnames({
            [styles.Checkbox_Inline]:
              displayOptions?.orientation === "horizontal",
          })}
        >
          {props.options.map((option) => (
            <div
              key={`checkbox-${props.name}-${option.value}`}
              className={classnames(
                styles.Checkbox,
                {
                  [styles.Checkbox_Inverse]: displayOptions?.inverse,
                },
                className
              )}
            >
              <label className={styles.Checkbox__label}>
                <input
                  {...allOtherProps}
                  type="checkbox"
                  name={props.name}
                  ref={register}
                  value={option.value}
                />
                <span>{option.label}</span>
              </label>
            </div>
          ))}
        </div>
      </InputWrapper>
    );
  }
);

export default CheckboxGroup;
