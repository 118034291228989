import classnames from "classnames";
import { Option } from "components/DemographicInformation/dropdown-options";
import React from "react";
import InputWrapper, { FormWrapperProps } from "../InputWrapper/InputWrapper";
import styles from "./RadioGroup.module.scss";

type RefReturn =
  | string
  | ((instance: HTMLInputElement | null) => void)
  | React.RefObject<HTMLInputElement>
  | null
  | undefined;

type Props = {
  options: Option[];
  register: RefReturn;
  wrapperClass?: string;
} & FormWrapperProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;

const RadioGroup = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { className, ...allOtherProps } = props;

  return (
    <InputWrapper {...allOtherProps} className={props.wrapperClass}>
      <div className={classnames(className, styles.radio)}>
        {props.options.map((option) => (
          <label>
            <input
              {...props}
              type="radio"
              name={props.name}
              ref={props.register}
              value={option.value}
              className={styles.option}
            />
            <span>{option.label}</span>
          </label>
        ))}
      </div>
    </InputWrapper>
  );
});

export default RadioGroup;
